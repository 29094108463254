//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
export default {
  data() {
    return {
      e1: 1,
      user: {},
      token: "",
      token_ok: false,
      loading_main: true,
      loading: false,
      show_pwd: false,
      show_2: false,
      nuova_pwd: "",
      ripeti_pwd: "",
      valid_new: true,
      valid_reap: true,
      rules_new: [
        (value) => value.length >= 8 || "Almeno 8 caratteri",
        (value) => !!value || "Specificare Password",
      ],
      rules_rep: [
        (value) => value == this.nuova_pwd || "Le password devono coincidere",
        (value) => !!value || "Ripetere la password",
      ],
    };
  },
  mounted() {
    var token = this.$route.query.token;
    axios
      .get(process.env.VUE_APP_API_ROOT + "/verify_reset_token/" + token)
      .then((res) => {
        this.loading_main = false;
        this.token_ok = true;
        this.user = res.data.user;

        this.token = token;
      })
      .catch(() => {
        this.loading_main = false;
      });
  },
  methods: {
    validate_pwd() {
      this.$refs.form_new.validate();
      if (this.valid_new) {
        this.e1 = 3;
      }
    },
    validate_reap() {
      this.$refs.form_reap.validate();
      if (this.valid_reap) {
        this.loading = true;
        var json = {
          token: this.token,
          new_pwd: this.nuova_pwd,
        };
        axios
          .post(process.env.VUE_APP_API_ROOT + "/change_password", json)
          .then(() => {
            this.loading = false;
            this.pwd_changed = true;
            this.e1 = 4;
          });
      }
    },
  },
};
